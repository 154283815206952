<template>
    <div class="container-fluid">
        <div class="col-sm-12">
            <div class="card">
                <div class="card-header d-flex justify-content-between">
                    <div class="header-title">
                        <h4 class="card-title">Mapa de formações</h4>
                    </div>
                </div>
                <div class="card-body">
                        <div class="column">
                            <!-- Extra info -->
                            <span v-if="resource && currentResourceEventsOnPeriod">{{currentResourceEventsOnPeriod}} dia(s) já registado(s) no período selecionado</span>
                            <span v-else-if="resource && !currentResourceEventsOnPeriod">Não tem registos no período selecionado</span>
                            <!-- Add event  -->
                            <div class="row m-2 p-1 justify-content-end">
                                <div v-if="showAdd" class="row flex-grow-1" style="gap:1rem">
                                    <select v-model="resource">
                                        <option selected disabled>Escolher recurso humano</option>
                                        <option v-for="resource in humanResources" :key="resource.email" :value="resource">{{resource["Nome"]}}</option>
                                    </select>
                                    <input type="date" v-model="startDate" placeholder="Data de início"/>
                                    <input type="date" v-model="endDate" placeholder="Data de fim"/>
                                    <select v-if="startDate && endDate && startDate == endDate" v-model="period">
                                        <option value="8">Dia completo</option>
                                        <option value="7">7 horas</option>
                                        <option value="6">6 horas</option>
                                        <option value="5">5 horas</option>
                                        <option value="4">4 horas</option>
                                        <option value="3">3 horas</option>
                                        <option value="2">2 horas</option>
                                        <option value="1">1 horas</option>
                                    </select>
                                </div>
                                <div class="row justify-content-end">
                                    <a v-if="!showAdd" class="bg-primary p-1" @click="showAdd = true" href="#">Adicionar</a>
                                    <a v-else :class="{'disabled': !resource || !startDate || !endDate}" class="bg-primary p-1" @click="addEvent" href="#">Confirmar</a>
                                </div>
                            </div>
                            <!-- Calendar -->
                            <div style="height: 500px">
                                <vue-cal locale="pt-br" ref="vuecal" :events="learningBlocks" :draggable="false" hide-weekends small :cell-click-hold="true" :editable-events="true" :time="false" @event-delete="removeItemRequested($event)" activeView="month" @ready="viewChanged" @view-change="viewChanged" :time-from="9 * 60" :time-to="18 * 60"/>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VueCal from 'vue-cal'
import 'vue-cal/dist/vuecal.css'

export default {
  components: { VueCal },
  data() {
        return {
            humanResources: [],
            learningBlocks: localStorage.getItem('learningBlocks') && Boolean(localStorage.getItem('learningBlocks')) ? JSON.parse(localStorage.getItem('learningBlocks')) : [],
            showAdd: false,
            resource: null,
            startDate: null,
            endDate: null,
            viewStartDate: null,
            viewEndDate: null,
            period: null
        }
   },
   computed: 
   {
    currentResourceEvents: function()
    {
        let self = this;

        return this.resource ? this.learningBlocks.filter(x => x.resource["Email"] === self.resource["Email"]) : [];
    },
    currentResourceEventsOnPeriod: function() // Get existing dates for the selected resource on the current period (viewStartDate-viewEndDate)
    {
        let self = this;

        // Get all vacation days
        var vacationDays = [];
        this.currentResourceEvents.forEach(function(event){
            vacationDays = vacationDays.concat(self.getDays(event.start, event.end));
        });

        // Count days in current interval
        var numDays = 0;
        vacationDays.forEach(function(date){
            if(new Date(date) >= new Date(self.viewStartDate) && new Date(date) <= new Date(self.viewEndDate))
            {
                numDays++;
            }
        });

        return numDays;
    }
   },
   mounted () {
       this.readTextFile('https://wilddourocrm.painelgestao.pt/csv/funcionarios.csv');
   },
   methods: {
       readTextFile(file) {
           this.humanResources = [];
           let self = this;

           let rawFile = new XMLHttpRequest();
           rawFile.open("GET", file, false);
           rawFile.onreadystatechange = function ()
           {
               if(rawFile.readyState === 4)
               {
                 if(rawFile.status === 200 || rawFile.status == 0)
                 {
                    let csv = rawFile.responseText;
                    let lines = csv.split('\n');

                    // Get structure of object
                    let headers = lines[0].split(";") 

                    for(let line = 1; line < lines.length; line++){
                       let values = lines[line].split(";");
                       let client = {};

                       for (let index = 0; index < values.length - 1; index++) {
                          let key = headers[index];
                          client[key] = values[index];
                       }
                       
                       self.humanResources.push(client);
                    }        
                 }
               }
           }
           rawFile.send(null);
       },
       addEvent()
       {
            try
            {
                let self = this;

                // Check if there is already something scheduled for this resource on these dates
                this.currentResourceEvents.forEach(function (event) {
                    if((new Date(event.start) >= new Date(self.startDate) && new Date(event.start) <= new Date(self.endDate)) || (new Date(event.end) >= new Date(self.startDate) && new Date(event.end) <= new Date(self.endDate)))
                    {
                        throw "Can't create the event";
                    }
                });

                // Proceed normally
                var diff = Math.floor((Date.parse(this.endDate) - Date.parse(this.startDate)) / 86400000);
                
                this.learningBlocks.push({resource: this.resource, title: `${this.resource["Nome"]} - ${this.period} hora(s)`, start: this.startDate, end: this.endDate, allDay: true, delete: true, days: diff});
                this.resource = null;
                this.startDate = null;
                this.endDate = null;
                this.period = null;
                this.showAdd = false;
            }
            catch
            {
                alert("Não foi possível registar os dias de férias assinalados. Por favor confirme que não existe sobreposição de datas.");
            }
       },
       removeItemRequested(event)
       {
            this.learningBlocks = this.learningBlocks.filter(function( obj ) {
                return obj.resource !== event.resource;
            });
       },
       viewChanged(params)
       {
        this.viewStartDate = params.startDate;
        this.viewEndDate = params.endDate;
       },
       getDays(start, end) {
            for(var arr=[],dt=new Date(start); dt<=new Date(end); dt.setDate(dt.getDate()+1)){
                arr.push(new Date(dt));
            }
            return arr;
        },
    },
    watch:
    {
        learningBlocks: function()
        {
            localStorage.setItem('learningBlocks', JSON.stringify(this.learningBlocks));
        }
    }
}
</script>